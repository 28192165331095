import { useRef, useEffect, useCallback } from "react";
import { Timeline as VisTimeline } from "vis-timeline";
import "vis-timeline/dist/vis-timeline-graph2d.min.css";

const events = [
  "changed",
  "click",
  "contextmenu",
  "currentTimeTick",
  "doubleClick",
  "drop",
  "groupDragged",
  "itemout",
  "itemover",
  "mouseDown",
  "mouseMove",
  "mouseOver",
  "mouseUp",
  "rangechange",
  "rangechanged",
  "select",
  "timechange",
  "timechanged",
];

const Timeline = ({ options, items, groups, eventHandlers }) => {
  const containerRef = useRef(null);
  const timelineRef = useRef(null);
  const eventHandlersRef = useRef(eventHandlers);

  useEffect(() => {
    eventHandlersRef.current = eventHandlers;
  }, [eventHandlers]);

  const onEvent = useCallback((eventType, arg) => {
    let handlers = eventHandlersRef.current;
    if (handlers && eventType in handlers) {
      handlers[eventType](arg);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      let timeline = timelineRef.current;
      if (!timeline) {
        timeline = new VisTimeline(containerRef.current, items, options);
        events.forEach(
          (eventType) =>
            timeline && timeline.on(eventType, (arg) => onEvent(eventType, arg))
        );
        timelineRef.current = timeline;
      } else {
        timeline.setOptions(options);
        timeline.setItems(items);
      }
      timeline.setGroups(groups);
    }
    return () => {
      if (timelineRef.current) {
        timelineRef.current.destroy();
        timelineRef.current = null;
      }
    };
  }, [onEvent, items, options, groups, containerRef]);

  return <div className="timeline" ref={containerRef} />;
};

export default Timeline;
