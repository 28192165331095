import React, { useState, useEffect, useRef } from "react";
import { DataSet, DataView } from "vis-data/standalone";

import Timeline from "./Timeline";
import Filters from "./Filters";
import { request, getList } from "./scoro";
import "./styles.css";

function contains(a, b) {
  return a.toLowerCase().includes(b.toLowerCase());
}

const App = () => {
  const groups = useRef(new DataSet());
  const items = useRef(new DataSet());

  const [isLoaded, setIsLoaded] = useState(false);
  const [filters, setFilters] = useState({});

  useEffect(async () => {
    if (!isLoaded) {
      const projects = await getList("projects", {
        filter: { status: "inprogress" },
      });

      await projects.map(async (project) => {
        await request(`projects/view/${project.project_id}`).then((p) => {
          groups.current.add({
            id: project.project_id,
            content: `${project.no}: ${project.project_name}`,
            name: project.project_name,
            tags: p?.data?.tags,
            title: `${project.no}: ${project.project_name}`,
          });
        });

        await getList("projectPhases", {
          filter: { project_id: project.project_id },
        }).then((phases) => {
          phases.forEach((phase) => {
            const start =
              phase.start_date === "0000-00-00"
                ? phase.end_date
                : phase.start_date;
            items.current.add({
              id: `phase-${phase.id}`,
              content: phase.title,
              start: start,
              end: phase.end_date,
              group: phase.project_id,
              type: phase.type === "milestone" ? "point" : "range",
              itemType: phase.type,
              className: phase.type,
            });
          });
        });

        await getList("tasks", {
          filter: { project_id: project.project_id },
        }).then((tasks) => {
          tasks.forEach((task) => {
            if (task.start_datetime && task.datetime_due) {
              const start = task.start_datetime.split("T", 1)[0];
              const end = task.datetime_due.split("T", 1)[0];
              items.current.add({
                id: task.event_id,
                content: task.event_name,
                start,
                end,
                group: task.project_id,
                type: start === end ? "point" : "range",
                itemType: "task",
                className: "task",
              });
            }
          });
        });
      });

      setIsLoaded(true);
    }
  }, [isLoaded]);

  const visibleGroups = new Set();
  const itemsView = new DataView(items.current, {
    filter: (data) => {
      const { searchTask } = filters;

      // exclude management
      if (data.content.toLowerCase().includes("management")) {
        return false;
      }

      // search tasks
      if (searchTask?.length) {
        for (const element of searchTask) {
          if (contains(data.content, element.value)) {
            visibleGroups.add(data.group);
            return true;
          }
        }
        return false;
      }

      return true;
    },
  });

  const groupsView = new DataView(groups.current, {
    filter: (data) => {
      const { service, searchJob, searchTask } = filters;

      // search jobs
      if (searchJob?.length) {
        for (const element of searchJob) {
          if (contains(data.content, element.value)) {
            return true;
          }
        }
        return false;
      }
      // filter services
      if (service && !data.tags.includes(service)) {
        return false;
      }

      // exclude jobs with no tasks
      if (searchTask?.length && !visibleGroups.has(data.id)) {
        return false;
      }

      return true;
    },
  });

  const min = itemsView.stream().min(({ start: s }) => +new Date(s))?.start;
  const max = itemsView.stream().max(({ end: e }) => +new Date(e))?.end;

  const options = {
    stack: filters.stacked || false,
    timeAxis: { scale: "day" },
    height: "100%",
    zoomMin: 14 * 86400000,
    zoomMax: 180 * 86400000,
    orientation: "top",
    groupHeightMode: filters.stacked ? "fixed" : "auto",
    tooltip: { template: (data) => data.content },
    groupOrder: "name",
    start: new Date(),
    ...(min ? { min } : {}),
    ...(max ? { max } : {}),
  };

  return (
    <div className="wrapper">
      {!isLoaded && <div className="loader">Loading...</div>}
      <div className="header">
        <h2>Designate Studio Gantt</h2>
        <a href="actions/logout.php">logout</a>
      </div>
      <Filters
        filters={filters}
        setFilters={setFilters}
        groups={groups.current}
        items={items.current}
        loaded={isLoaded}
      />
      <Timeline groups={groupsView} items={itemsView} options={options} />
    </div>
  );
};

export default App;
