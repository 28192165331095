import React, { useMemo } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { set as _set } from "lodash";

const Filters = ({ filters, setFilters, items, groups, loaded }) => {
  const set = (filter, value) =>
    setFilters(_set({ ...filters }, filter, value));

  const groupOptions = useMemo(
    () => groups.distinct("content").map((value) => ({ value, label: value })),
    [groups, loaded]
  );

  const taskOptions = useMemo(
    () => items.distinct("content").map((value) => ({ value, label: value })),
    [items, loaded]
  );

  return (
    <div className="controls">
      <label>
        <input
          type="checkbox"
          checked={filters?.stacked}
          onChange={({ target }) => set("stacked", target.checked)}
        />
        Stacked
      </label>
      <Select
        className="select"
        onChange={({ value }) => set("service", value)}
        options={[
          { value: "", label: "All Services" },
          { value: "Investor Comms", label: "Investor Comms" },
          { value: "Brand", label: "Brand" },
          { value: "Digital", label: "Digital" },
          { value: "Marketing", label: "Marketing" },
        ]}
      />
      <CreatableSelect
        className="select"
        isMulti
        onChange={(value) => set("searchJob", value)}
        placeholder="Search Job"
        options={groupOptions}
        createOptionPosition="first"
      />
      <CreatableSelect
        className="select"
        isMulti
        onChange={(value) => set("searchTask", value)}
        placeholder="Search Task/Phase"
        options={taskOptions}
        formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
        createOptionPosition="first"
      />
    </div>
  );
};

export default Filters;
