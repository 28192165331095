import Bottleneck from "bottleneck";

const limiter = new Bottleneck({ minTime: 1000 / 18 }); // 18 requests per second
const PROXY = "https://gantts.designate.com.au/proxy.php/";
// "http://studio-gantt.lndo.site/proxy.php/"

export const request = async (endpoint, options) => {
  const response = await limiter.schedule(() =>
    fetch(PROXY + endpoint, {
      method: "POST",
      body: JSON.stringify(options),
    })
  );
  if (!response.ok) throw response;
  return response.json();
};

export const getList = async (endpoint, options = {}) => {
  let page = 0;
  let result = [];
  do {
    page++;
    const response = await request(endpoint, { ...options, page });
    result = [...result, ...response?.data];
  } while (result.length === 100 * page);
  return result;
};

export const getRelatedObjects = async (type, id, module) => {
  let page = 0;
  let result = [];
  do {
    page++;
    const response = await request(`${type}/getRelatedObjects/${id}`, {
      page,
      request: { modules: [module] },
    });
    result = [...result, ...response?.data?.[module]?.items];
  } while (result.length === 100 * page);
  return result;
};
